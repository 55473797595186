$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1300px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px
);

$title: 80px;
$title-md: 64px;
$title-sm: 54px;

$desc: 24px;
$desc-md: 24px;
$desc-sm: 24px;
