@font-face {
  font-family: 'DINProLight';
  src: url('../fonts/subset-DINPro-Light.eot');
  src: local('DINPro-Light'),
  url('../fonts/subset-DINPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-DINPro-Light.woff2') format('woff2'),
  url('../fonts/subset-DINPro-Light.woff') format('woff'),
  url('../fonts/subset-DINPro-Light.ttf') format('truetype'),
  url('../fonts/subset-DINPro-Light.svg#DINProLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINProBold';
  src: url('../fonts/subset-DINPro-Bold.eot');
  src: local('DINPro-Bold'),
  url('../fonts/subset-DINPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-DINPro-Bold.woff2') format('woff2'),
  url('../fonts/subset-DINPro-Bold.woff') format('woff'),
  url('../fonts/subset-DINPro-Bold.ttf') format('truetype'),
  url('../fonts/subset-DINPro-Bold.svg#DINProBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINProRegular';
  src: url('../fonts/subset-DINPro-Regular.eot');
  src: local('DINPro-Regular'),
  url('../fonts/subset-DINPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-DINPro-Regular.woff2') format('woff2'),
  url('../fonts/subset-DINPro-Regular.woff') format('woff'),
  url('../fonts/subset-DINPro-Regular.ttf') format('truetype'),
  url('../fonts/subset-DINPro-Regular.svg#DINProRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Bold';
  src: url('../fonts/BebasNeue-Bold.eot');
  src: url('../fonts/BebasNeue-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeue-Bold.woff2') format('woff2'),
  url('../fonts/BebasNeue-Bold.woff') format('woff'),
  url('../fonts/BebasNeue-Bold.ttf') format('truetype'),
  url('../fonts/BebasNeue-Bold.svg#BebasNeue-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}


//@font-face {
//  font-family: 'DINProMedium';
//  src: url('subset-DINPro-Medium.eot');
//  src: local('DINPro-Medium'),
//  url('subset-DINPro-Medium.eot?#iefix') format('embedded-opentype'),
//  url('subset-DINPro-Medium.woff2') format('woff2'),
//  url('subset-DINPro-Medium.woff') format('woff'),
//  url('subset-DINPro-Medium.ttf') format('truetype'),
//  url('subset-DINPro-Medium.svg#DINProMedium') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'DINProBlack';
//  src: url('subset-DINPro-Black.eot');
//  src: local('DINPro-Black'),
//  url('subset-DINPro-Black.eot?#iefix') format('embedded-opentype'),
//  url('subset-DINPro-Black.woff2') format('woff2'),
//  url('subset-DINPro-Black.woff') format('woff'),
//  url('subset-DINPro-Black.ttf') format('truetype'),
//  url('subset-DINPro-Black.svg#DINProBlack') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

