

@import "./variables/dimen.scss";
@import "./base/index";
@import "./base/fonts";
@import "./variables/colors.scss";

.img-fluid {
    max-width: 100%;
}

//// SECTION EFFIE

.section-effie {

    .text-silver {
        color: #DBDBDB;
    }

    .text-golden {
        color: #AC8236;
    }

    .awards {
        margin-top: 15px;
        justify-content: flex-start;
    }

    .effie-award {

        position: absolute;
        z-index: 9995;

        @include media-breakpoint-up(xl) {
            width: 456px;
            height: 377px;
            top: -100px;
            left: -30px;
        }

        @include media-breakpoint-down(lg) {
            top: 0;
        }

        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }

    .section-desc {
        margin-bottom: 30px;
    }

    .image-switcher {
        @include media-breakpoint-down(xs) {
            margin-top: 30px;
        }
    }

    .effie-image {
        margin-top: 160px;

        @include media-breakpoint-down(xs) {
            margin-top: 0
        }

        @include media-breakpoint-up(xl) {
            margin-top: 240px;
        }

        @include media-breakpoint-down(sm) {
            .image-swapper-preview {
                display: flex;
                justify-content: center;
            }
        }
    }

}

//// END SECTION EFFIE

//// SECTION FAS

.modal-backdrop.show {
    z-index: 99994;
}

.modal-content {
    .close {
        position: absolute;
        z-index: 99995;
        background: white;
        border-radius: 50px;
        opacity: 0.9;
        padding: 6px 12px;
        right: -18px;
        top: -18px;

        &:hover {
            opacity: 1!important;
        }

        @include media-breakpoint-down(xs) {
            top: -50px;
            right: 5px;
        }
    }
}

.modal-dialog {
    @include media-breakpoint-down(xs) {
        margin-top: 60px;
    }
}

.section-fas {

    @include media-breakpoint-down(sm) {
        padding-top: 100px;
    }

    .video-container {
        position:relative;
        padding-bottom:56.25%;
        padding-top:30px;
        height:0;
        overflow:hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    .modal {
        z-index: 99995;

        @include media-breakpoint-down(sm) {
            padding: 0!important;
        }

        .modal-body {
            min-height: auto;
        }
    }

    .fas-logo {
        position: absolute;
        z-index: 3;
        top: -92px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 182px;
        height: 175px;

        @include media-breakpoint-up(xl) {
            top: -142px;
            width: 232px;
            height: 225px;
        }

        @include media-breakpoint-down(sm) {
            width: 132px;
            height: 125px;
            top: -100px;
        }

        @include media-breakpoint-down(xs) {
            top: -100px;
        }
    }

    .section-banner {
        .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            @include media-breakpoint-up(xl) {
                width: 209px;
                height: 210px;
            }
        }
    }


    .section-title {
        margin-top: 40px;

        @include media-breakpoint-up(xl) {
            margin-top: 80px;
        }

        margin-bottom: 40px;
    }

    .image-switcher {

        width: 290px;
        height: 290px;
        margin-top: 30px;

        @include media-breakpoint-up(xl) {
            margin-top: -120px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
        }

    }

    .section-image {
        margin-top: 60px;
    }
}

//// END SECTION FAS

//// SECTION ELEARN

.section-elearn {

    .section-title {
        text-align: center;
    }

    .section-desc {
        text-align: center;
        margin-bottom: 60px;
    }

    .section-banner {
        width: 100%;
        z-index: 99;
        video {
            width: 100%;
            object-fit: fill;
        }
    }

    .figure-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: -90px;

        .figure {
            z-index: -1;
            &.digital-award {
                z-index: 999;
                @include media-breakpoint-down(xs) {
                    padding: 0;
                }
            }

            @include media-breakpoint-down(xs) {
                padding: 30px 0;
            }
        }

        @include media-breakpoint-down(xs) {
            margin-top: -30px;
        }
    }


    .digital-award {
        z-index: 10;
        @include media-breakpoint-down(xs) {
            width: 320px;
        }
    }

    .awards {
        width: 100%;
        flex-direction: column;
        .award-container {
            justify-content: center;
        }
    }
}

//// END SECTION ELEARN

#cookies {
    position: fixed;
    bottom: 0;
    background: $company-color;
    color: white;
    padding: 15px;
    left: 0;
    width: 100%;
    border-top: 4px solid white;
    z-index: 99999;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a {
        color: black;
    }

    .cookie-ok {
        background: white;
        padding: 8px;
        height: 50px;
        width: 200px;
        color: black;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 4px;
            right: 4px;
            top: 4px;
            bottom: 4px;
        }
    }
}

.reverse-xs {
    @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;
    }
}

.appear-on-scroll {
    position: relative;
    z-index: 10;

    @include media-breakpoint-down(md) {
        transform: none!important;
    }
}

.reveal-on-scroll {
    // opacity: 0;
	// transform: translate3d(0, 100px, 0);
    // transition: all 0.7s ease-in-out;
    //
    // &.revealed {
    //     opacity: 1;
	// 	transform: none;
    // }
}

.hidden {
    display: none!important;
}

.zoom {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .zoom-desc {
        position: absolute;
        color: #ffffff;
        font-family: "DINProBold";
        font-size: 27px;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        user-select: none;
    }

    img {
        transition: transform 0.5s, filter 0.5s ease-in-out;
    }

    &:hover {
        .zoom-desc {
            opacity: 1;
        }
        img {
            transform: scale(1.2);
            filter: brightness(80%);
        }
    }


}

body {
    font-family: "DINProRegular";
}

a {
    color: $text-color;
    &:hover {
        text-decoration: none;
        color: $text-color;
    }
}

.text-company {
    color: $company-color;
}

strong {
    font-family: "DINProBold";
}

.animation-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header-animation {
    width: 780px;
    height: auto;
    margin-top:-60px;

    @include media-breakpoint-down(md) {
        width: 600px;
        margin-top:-40px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    @include media-breakpoint-down(xs) {
        margin-top: 40px;
    }
}

.header-section {
    margin-top: 30px;
}

.awards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .main-award {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        .award {
            .award-name {
                font-size: 22px;

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }

            }
            .award-small {
                font-size: 11px;
                @include media-breakpoint-down(md) {
                    font-size: 9px;
                }
            }
        }
    }
}

.awards {
    .award+.award {
        margin-left: 17px;
    }
}


.award-container {
    display: inline-flex;

    @include media-breakpoint-down(xs) {
        width: 100%;
        justify-content: center;
    }
}
.award {
    display: inline-flex;
    align-items: center;
    font-family: "DINProLight", sans-serif;
    text-align: center;
    margin-bottom: 20px;
    &.award-black use {
        fill: #000;
    }
    &.award-gold use {
        fill: $gold-color;
    }
    .award-begin,
    .award-end {
        width: 25px;
    }
    .award-content {
        padding: 0 9px;
    }
    .award-name {
        font-size: 15px;
        line-height: 1;
        display: block;
        margin-bottom: 4px;
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }
    .award-small {
        font-size: 9px;
        line-height: 1;
        display: block;
    }
}

header.main-header {
    display: flex;
    align-items: center;

    min-height: 78px;
    position: relative;
}

.logo {
    position: fixed;
    top: 30px;
    z-index: 99990;
}
.logo-text {
    font-family: "DINProBold";
    font-size: 16px;
    font-weight: 700;
    line-height: 15px;
    padding-left: 30px;
    position: absolute;
    left: 80px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.contact {
    position: fixed;
    top: 55px;
    right: 30px;
    z-index: 99991;

    @include media-breakpoint-down(sm) {
        right: 15px;
    }
}

.section-title {
    color: $text-color;
    font-family: "DINProBold";
    font-size: $title;
    line-height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;


    @include media-breakpoint-down(md) {
        font-size: $title-md;
        line-height: 64px;
    }

    @include media-breakpoint-down(sm) {
        font-size: $title-sm;
        line-height: 54px;
    }
}

.section-desc {
    font-size: $desc;
    font-weight: 400;
    line-height: 32px;

    @include media-breakpoint-down(md) {
        font-size: $desc-md;
        line-height: 28px;
    }

    @include media-breakpoint-down(sm) {
        font-size: $desc-sm;
    }

}

//// SECTION MAIN
.section-main {
    margin-bottom: 80px;

    // .section-desc {
    //     margin-left: 36px;

    //     @include media-breakpoint-down(sm) {
    //         margin-left: 0;
    //     }
    // }

    @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
    }

    .section-desc {
        margin-top: -20px;
        @include media-breakpoint-down(xs) {
            margin-top: 30px;
        }
    }

}

//// SECTION LEO
.section-leo {
    position: relative;
    .awards {
        margin-top: 60px;
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }
    }
    .leo-wings {
        position: absolute;
        top: -92px;
        @include media-breakpoint-down(sm) {
            position: static;
        }
    }
    .section-title {
        color: $title-color;
        position: relative;
        margin-top: 80px;
        margin-left: 21%;
        z-index: 2;

        @include media-breakpoint-down(md) {
            margin-left: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
    .ec-1 {
        margin-top: 95px;
        img {
            max-width: 100%;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .leo {
        margin-top: 20px;
        img {
            max-width: 100%;
        }
    }
    .leo-stars {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        background: black;
        .bcg {
            width: 100%;
            height: 820px;
            background: url("../images/leo-stars-parallax.png");
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            z-index: 1;
            @include media-breakpoint-down(md) {
                background-position: top;
                background-size: contain;
            }
        }

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }

    }

    @include media-breakpoint-down(sm) {
        .leo-image-switcher {
            display: flex;
            justify-content: center;
        }
    }
}

.section-divider {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;


    @include media-breakpoint-down(sm) {
        min-height: 300px;
    }
}

.scroll-down {
    width: 24px;
    height: 24px;
    background: $company-color;
}

//// SECTION BANKING
.section-banking {
    .award {
        margin-top: 60px;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    .award + .award {
        margin-left: 15px;
    }

    .iwuc-awards {
        position: relative;
        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(md) {
            margin-top: -60px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }

        .mix-award {
            position: absolute;
            top: 0;
            right: 0;

            @include media-breakpoint-down(xs) {
                position: relative;
                top: 20px;
                right: 20px;
            }
        }

        .golden-arrow {
            width: 100%;
            position: absolute;
            top: -36px;
            left: -46px;
            z-index: -1;

            @include media-breakpoint-down(xs) {
                position: relative;
                top: initial;
                left: initial;
                width: 250px;
            }
        }

        @include media-breakpoint-down(xs) {
            display: flex;
            flex-direction: row-reverse;

        }

        // @include media-breakpoint-down(sm) {
        //     margin-top: 18px;
        // }
    }

    .ing-logo {
        img {
            max-width: 100%;
        }
        @include media-breakpoint-down(sm) {
            padding-right: 0;
            margin-bottom: 15px;
            // padding-left: 35px;
        }
    }

    .video-player {
        margin-top: 80px;
        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }

    .iwuc {
        margin-top: 34px;
        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }

    @include media-breakpoint-down(sm) {
        .iwuc-image-switcher {
            display: flex;
            justify-content: center;
        }
    }
    .play-button {
        margin-top: 100px;
        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

//// SECTION ICONO
.section-icono {
    .section-desc {
        display: flex;
        .ing-logo {
            margin-left: 10px;
            margin-right: 20px;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
                margin-bottom: 15px;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    .award {
        margin-top: 40px;
    }
    .poc-award {
        margin-top: 60px;
        img {
            max-width: 100%;
        }
    }

    .icono-lg {
        display: none;
        @include media-breakpoint-up(sm) {
            display: flex;
        }
    }

    .icono-sm {
        display: none;
        @include media-breakpoint-down(xs) {
            display: flex;
        }
    }

    .icono {



        img {
            max-width: 100%;
        }

        &.icono-1 {
            margin-top: -300px;
        }

        &.icono-2 {
            margin-top: 200px;
        }

        &.icono-3 {
            margin-top: 83px;
        }

        &.icono-4 {
            margin-top: 128px;
        }

        &.icono-5 {
            margin-top: -56px;
        }

        &.icono-1, &.icono-2, &.icono-3, &.icono-4, &.icono-5 {
            @include media-breakpoint-down(sm) {
                margin-top: 30px;;
            }
        }
    }
}

//// SECTION SKANSKA
.section-skanska {
    .section-title {
        margin-left: 105px;
    }
    .section-logo {
        margin-bottom: 24px;
        img {
            max-width: 100%;
        }
    }
    .process {
        margin-left: -25px;
        img {
            max-width: 100%;
        }
    }
    .section-desc {
        text-align: right;
        margin-right: 220px;
    }

    @include media-breakpoint-down(lg) {
        .section-title {
            margin-left: 80px;
        }
        .section-desc {
            margin-right: 160px;
        }
    }


    @include media-breakpoint-down(md) {
        .section-title {
            margin-left: 0;
        }

        .section-desc {
            margin-right: 0;
        }
    }


    @include media-breakpoint-down(sm) {
        .section-logo {
            margin-top: 30px;
        }

        .section-desc {
            text-align: left;
        }

        .process {
            margin-left: 0;
        }
    }
}

.scrollmagic-pin-spacer {
    -webkit-overflow-scrolling: auto !important;
    z-index: 9999;
}

//// SECTION LAST YEARS
.section-last-years {
    .section-title {
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 120px;
        }

        &.pinned {
            font-size: 42px;
            top: 27px!important;
            z-index: 999999999;
            -webkit-overflow-scrolling: auto !important;

            br {
                display: none;
            }

            @include media-breakpoint-down(md) {
                font-size: 36px;
                left: 100px!important;
                top: 40px!important;
            }

            @include media-breakpoint-down(xs) {
                width: 100%!important;
                font-size: 7vw;
                word-break: keep-all;
                white-space: nowrap;
                padding: 0 15px;
                left: 0!important;
                top: 105px!important;
            }
        }
    }

    .achievment {
        position: relative;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 160px;
        width: 100%;

        .achievment-title {
            display: flex;
            align-items: baseline;
            margin-left: 40px;
            margin-bottom: 80px;

            @include media-breakpoint-down(md) {
                // font-size: $desc-md;
            }

            @include media-breakpoint-down(sm) {
                // font-size: $desc-sm;
                margin-bottom: 30px;

                br {
                    display: none;
                }
            }

            @include media-breakpoint-down(xs) {
                flex-direction: column;
                margin-left: 0!important;
            }

            .bullet {
                width: 54px;
                &:before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: $company-color;
                    margin-right: 30px;
                }
                @include media-breakpoint-down(xs) {
                    margin-bottom: 5px;
                }
            }
            .text {
                width: 90%;
            }
        }
        .achievment-image {
            img {
                max-width: 100%;
            }
        }
        &.achievment-1 {

            .achievment-images {
                position: relative;
                z-index: 1;
                min-height: 550px;

                @include media-breakpoint-down(lg) {
                    display: flex;
                    flex-direction: row;
                    min-height: auto;
                    margin-top: 30px;

                    .achievment-image + .achievment-image {
                        margin-left: 30px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                    margin-top: 0;
                    .achievment-image + .achievment-image {
                        margin-left: 0;
                    }
                }

            }

            @include media-breakpoint-down(sm) {
                .achievment-image {
                    position: static;
                    width: 100%;
                    margin-left: 0;
                    margin-top: 30px;
                }
            }

            .achievment-image-1 {
                left: 0;
                top: 160px;
            }

            .achievment-image-2 {
                top: -80px;
                right: 34%;
            }

            .achievment-image-3 {
                right: 0;
                top: 40px;
            }

            .achievment-image {
                position: absolute;

                @include media-breakpoint-down(lg) {
                    position: relative;
                    top: initial;
                    left: initial;
                    right: initial;
                }
            }

            .achievment-title {
                position: relative;
                margin-bottom: 0;
                z-index: 10;
            }



        }
        &.achievment-2 {
            margin-bottom: 226px;
            .achievment-title {
                margin-top: 60px;

                margin-left: 30px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                }
            }
            .achievment-image-1 {
                position: absolute;
                margin-left: 84px;
                bottom: -66px;
                @include media-breakpoint-down(sm) {
                    position: static;
                    margin-left: 0;
                    margin-bottom: 30px;
                }


                @include media-breakpoint-down(xs) {
                    img {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }


        }
        &.achievment-3 {
            .achievment-images {
                .achievment-image+.achievment-image {
                    margin-left: 30px;
                }
                .achievment-image-1 {
                    margin-top: 71px;
                }
                .achievment-image-2 {
                    margin-top: 11px;
                }
                .achievment-image-3 {
                    margin-top: 82px;
                }

                @include media-breakpoint-down(sm) {

                    flex-wrap: wrap;
                    justify-content: space-between;

                    .achievment-image+.achievment-image {
                        margin-left: 0;
                    }

                    .achievment-image {
                        width: 45%;
                        margin-top: 30px;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 160px;

                .achievment-title {
                    margin-bottom: 30px;
                }
            }
        }
        &.achievment-4 {


            .achievment-images {
                display: flex;
                flex-direction: column;


                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

            .achievment-image-1 {
                margin-top: 87px;
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }

            .achievment-image-3 {
                margin-top: 35px;
            }

            .achievment-image-4 {
                margin-top: 37px;
                margin-left: auto;
            }
        }

        &.achievment-5 {

            .achievment-title {
                margin-left: 50px;
            }

            .achievment-images {
                display: flex;
                .achievment-image + .achievment-image {
                    margin-left: 15px;
                }
            }

            .achievment-image-4 {
                margin-top: -120px;

                @include media-breakpoint-down(md) {
                    margin-top: -80px;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 30px;

                    display: flex;
                    justify-content: center;
                    img {
                        display: block;
                    }
                }
            }

            .achievment-image-5 {
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

        }

        &.achievment-6 {

            .achievment-image-1 {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    justify-content: center;
                    img {
                        display: block;

                    }
                }
            }

            .achievment-image-2 {
                margin-top: 120px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

            .achievment-image-3 {
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

        }

        &.achievment-7 {

            .achievment-title {
                margin-left: 50px;
            }

            .achievment-images {
                display: flex;
                align-items: flex-end;
                margin-top: 80px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                    justify-content: center;
                }
                .achievment-image + .achievment-image {
                    margin-left: 8px;
                }
            }

            .achievment-image-5 {
                margin-top: -51px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

            .achievment-image-1 {
                margin-top: 37px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }

            .achievment-image-4 {
                margin-top: -50px;
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                    display: flex;
                    justify-content: center;
                    img {
                        display: block;

                    }
                }
            }
        }
    }
}

//// SECTION DIGITAL
.section-digital {

    .digital-campaign-1 {
        margin-top: 80px;
    }

}

.footer {
    margin-top: 160px;
    margin-bottom: 160px;

    font-size: $desc;
    font-weight: 400;

    @include media-breakpoint-down(md) {
        font-size: $desc-md;
        line-height: 28px;
    }

    @include media-breakpoint-down(sm) {
        font-size: $desc-sm;
    }

    .footer-social {
        margin-top: 120px;
        p {
            margin-bottom: 0;
        }
    }

    .social-links {
        display: flex;

        margin-top: 30px;

        a + a {
            margin-left: 120px;

            @include media-breakpoint-down(lg) {
                margin-left: 60px;
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            a + a {
                margin-left: 0;
            }
        }
    }

    a.social-media {
        font-family: "DINProBold";
        color: $company-color;
        font-size: $title-md;
        font-weight: 700;
        @include media-breakpoint-down(md) {
            font-size: $title-sm;
            line-height: 64px;

            margin-bottom: 30px;
        }

    }

    .signature {
        margin-top: 160px;
        margin-bottom: 160px;

        @include media-breakpoint-down(lg) {
            margin-top: 80px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
        .thank-you {
            display: block;
            margin-bottom: 30px;
        }
    }


}

@include media-breakpoint-down(md) {
    .section-main {
        display: flex;
        align-items: center;
    }

}


// @include media-breakpoint-down(lg) {
//     body {
//         background: whitesmoke;
//     }
// }

// @include media-breakpoint-down(md) {
//     body {
//         background: lightgreen;
//     }
// }

// @include media-breakpoint-down(sm) {
//     body {
//         background: lightblue;
//     }
// }

// @include media-breakpoint-down(xs) {
//     body {
//         background: wheat;
//     }
// }
